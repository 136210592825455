$(document).ready(function () {
  const $items = $(".item");
  let currentIndex = 0;
  let isDragging = false;
  let startX = 0;
  let currentX = 0;

  function updateCarousel() {
    $items.removeClass("active left right hidden");

    // Calcular los índices de las tarjetas visibles
    const leftIndex = (currentIndex - 1 + $items.length) % $items.length;
    const rightIndex = (currentIndex + 1) % $items.length;

    // Asignar clases a las tarjetas visibles
    $items.eq(currentIndex).addClass("active");
    $items.eq(leftIndex).addClass("left");
    $items.eq(rightIndex).addClass("right");

    // Ocultar las tarjetas restantes
    $items.each(function (i) {
      if (i !== currentIndex && i !== leftIndex && i !== rightIndex) {
        $(this).addClass("hidden");
      }
    });
  }

  // Inicializar el carrusel
  updateCarousel();

  // Detectar clics en el slider para navegar
  $(".slider").on("click", function (event) {
    const sliderWidth = $(this).width();
    const clickX = event.pageX - $(this).offset().left;

    // Si el clic está en el lado izquierdo del slider
    if (clickX < sliderWidth * 0.1) {
      // 10% desde el borde izquierdo
      currentIndex = (currentIndex - 1 + $items.length) % $items.length;
      updateCarousel();
    }

    // Si el clic está en el lado derecho del slider
    if (clickX > sliderWidth * 0.9) {
      // 10% desde el borde derecho
      currentIndex = (currentIndex + 1) % $items.length;
      updateCarousel();
    }
  });

  // Evento de mousedown para empezar a arrastrar
  $(".slider").on("mousedown", function (event) {
    isDragging = true;
    startX = event.pageX;
    currentX = startX;
  });

  // Evento de mousemove para detectar el arrastre
  $(document).on("mousemove", function (event) {
    if (isDragging) {
      currentX = event.pageX;
    }
  });

  // Evento de mouseup para terminar el arrastre
  $(document).on("mouseup", function () {
    if (isDragging) {
      const deltaX = currentX - startX;

      if (Math.abs(deltaX) > 50) {
        // Un umbral para determinar el swipe
        if (deltaX < 0) {
          // Swipe hacia la izquierda (siguiente)
          currentIndex = (currentIndex + 1) % $items.length;
        } else {
          // Swipe hacia la derecha (anterior)
          currentIndex = (currentIndex - 1 + $items.length) % $items.length;
        }
        updateCarousel();
      }
      // Resetear variables de arrastre
      isDragging = false;
      startX = 0;
      currentX = 0;
    }
  });

  // Swipe izquierda (siguiente) con touch
  $(".slider").on("swipeleft", function () {
    currentIndex = (currentIndex + 1) % $items.length;
    updateCarousel();
  });

  // Swipe derecha (anterior) con touch
  $(".slider").on("swiperight", function () {
    currentIndex = (currentIndex - 1 + $items.length) % $items.length;
    updateCarousel();
  });

  // También se puede hacer clic en una tarjeta para activarla
  $items.on("click", function () {
    currentIndex = $(this).index();
    updateCarousel();
  });
});

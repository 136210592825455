import $ from "jquery"; // Importa jQuery desde npm

$(document).ready(function () {
  $("body").css("visibility", "visible");
  $("body").addClass("fade-in");

  initHeaderEvents();
  initNavigationEvents();
  agregarClaseNavActivo();
  logo_clickeable();

  function initHeaderEvents() {
    $(document).on("click", ".opn-nav", function (event) {
      event.stopPropagation();
      $("header section nav").toggleClass("expand");
      $("button.opn-nav div.bars").toggleClass("exe");
      $("header").toggleClass("color");
      $("span.menu_tag").toggleClass("resize");
    });

    $(document).on("click", function (event) {
      if (
        !$(event.target).closest("header section nav").length &&
        !$(event.target).closest(".opn-nav").length
      ) {
        $("header section nav").removeClass("expand");
        $("button.opn-nav div.bars").removeClass("exe");
        $("header").removeClass("color");
        $("span.menu_tag").removeClass("resize");
      }
    });
  }

  // Rescatar el elemento em con la clase 'anho'
  const anhoElemento = document.querySelector(".anho");

  // Obtener el año actual
  const anhoActual = new Date().getFullYear();

  // Asignar el año actual al contenido del elemento
  anhoElemento.textContent = anhoActual;

  initNavigationEvents();

  let domain = "https://skynbyhector.com/";
  let home_ = domain;
  let services_ = domain + "services";
  let about_ = domain + "about";
  let contact_ = domain + "contact";
  const pages = [
    { page: "home", file: home_ },
    { page: "services", file: services_ },
    { page: "about", file: about_ },
    { page: "contact", file: contact_ },
  ];

  // Inicializa los eventos de navegación
  function initNavigationEvents() {
    $(".home, .services, .about, .contact").each(function () {
      $(this).click(function () {
        var clickedClass = $(this).attr("class").split(" ")[0]; // Obtener la primera clase, que indica la página
        var pageObj = pages.find(function (page) {
          return page.page === clickedClass; // Buscar el objeto de la página que coincide
        });
        if (pageObj) {
          var file = pageObj.file; // Obtener la URL del archivo
          window.location.href = file; // Redirigir a la página correspondiente
        }
      });
    });
  }

  // Función para añadir clase de nav activo
  function agregarClaseNavActivo() {
    const currentPath = window.location.pathname.toLowerCase(); // Ruta actual en minúsculas
    console.log("Ruta actual:", currentPath); // Debug para confirmar la ruta

    $(".page").each(function () {
      const pageName = $(this).attr("class").split(" ")[0]; // Extraer la primera clase como nombre de página
      console.log("Verificando enlace para:", pageName); // Debug

      // Comparar si la ruta actual contiene el nombre de la página o si es la home
      if (
        currentPath.includes(pageName) ||
        (currentPath === "/" && pageName === "home")
      ) {
        $(this).addClass("link_nav_activo"); // Añadir clase activa
        console.log("Clase activa añadida para:", pageName); // Debug
      }
    });

    // Opción adicional: marcar home como activa si la ruta es solo "/"
    if (currentPath === "/") {
      $(".home").addClass("link_nav_activo");
      console.log("Clase activa añadida para: home"); // Debug
    }
  }

  function logo_clickeable() {
    $("header section picture img").on("click", function () {
      window.location.href = domain;
    });
  }

  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 150) {
      $("header").addClass("sticky_header");
      $("button.top").addClass("toTop");
    } else {
      $("header").removeClass("sticky_header");
      $("button.top").removeClass("toTop");
    }
  });

  $(".top").click(function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  });

  $(document).ready(function () {
    if (
      window.location.pathname.includes("index.html") ||
      window.location.pathname === "/"
    ) {
      initCounters(); // Solo ejecuta en el index.html

      function initCounters() {
        const $yearsElement = $(".years");
        const $co_facial = $(".co_facial");
        const $patients_treateds = $(".patients_treateds");

        let yearsCounter = 0,
          coFacialCounter = 0,
          patientsTreatedCounter = 0;
        let countersStarted = false;

        function isElementInViewport(el) {
          const rect = el.getBoundingClientRect();
          return (
            rect.top >= 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight)
          );
        }

        function startCounters() {
          if (!countersStarted) {
            countersStarted = true;
            setInterval(() => incrementCounter($yearsElement, 1, 5), 100);
            setInterval(() => incrementCounter($co_facial, 500, 20), 20);
            setInterval(() => incrementCounter($patients_treateds, 1000, 5), 5);
          }
        }

        function incrementCounter($element, maxValue, step) {
          let currentValue = parseInt($element.text(), 10) || 0;
          if (currentValue < maxValue) {
            $element.text(currentValue + step);
          }
        }

        $(window).on("scroll", function () {
          if (isElementInViewport($yearsElement[0])) {
            startCounters();
          }
        });
      }
    }
  });

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    if (
      window.location.pathname.includes("services.html") ||
      window.location.pathname === "/services" ||
      window.location.pathname.includes("index.html") ||
      window.location.pathname === "/"
    ) {
      let domain = "https://skynbyhector.com/";
      $("#co2laser").on("click", function () {
        window.location.href = domain + "co2laser";
      });

      $("#vipeel").on("click", function () {
        window.location.href = domain + "vipeel";
      });

      $("#filler").on("click", function () {
        window.location.href = domain + "filler";
      });

      $("#prp").on("click", function () {
        window.location.href = domain + "prp";
      });

      $("#kybella").on("click", function () {
        window.location.href = domain + "kybella";
      });

      $("#botox").on("click", function () {
        window.location.href = domain + "botox";
      });
    }
  });
  $(document).ready(function () {
    if (
      window.location.pathname.includes("botox.html") ||
      window.location.pathname === "/botox" ||
      window.location.pathname.includes("filler.html") ||
      window.location.pathname === "/filler" ||
      window.location.pathname.includes("vipeel.html") ||
      window.location.pathname === "/vipeel" ||
      window.location.pathname.includes("co2laser.html") ||
      window.location.pathname === "/co2laser"
    ) {
      console.log("aqui es" + window.location.pathname);

      $(document).ready(function () {
        let currentPatientIndex = 0;
        const $patients = $(".wrapper");
        const $prevPatientButton = $(".left");
        const $nextPatientButton = $(".right");

        // Función para mostrar el paciente activo
        function showPatient(index) {
          $patients.each(function (i) {
            $(this).toggleClass("patient_active", i === index);
          });
          updateActivePatientName(); // Actualiza el nombre del paciente activo
        }

        // Evento para el botón "prev patient"
        $prevPatientButton.on("click", function () {
          currentPatientIndex =
            (currentPatientIndex - 1 + $patients.length) % $patients.length;
          showPatient(currentPatientIndex);
        });

        // Evento para el botón "next patient"
        $nextPatientButton.on("click", function () {
          currentPatientIndex = (currentPatientIndex + 1) % $patients.length;
          showPatient(currentPatientIndex);
        });

        // Control de imágenes dentro de cada paciente
        $patients.each(function () {
          const $images = $(this).find(".carousell img");
          let currentImageIndex = 0;

          // Función para mostrar la imagen activa
          function showImage(index) {
            $images.each(function (i) {
              $(this).css("display", i === index ? "block" : "none");
            });
          }

          const $prevImgButton = $(this).find(".prev_img");
          const $nextImgButton = $(this).find(".next_img");

          // Evento para el botón "prev_img"
          $prevImgButton.on("click", function () {
            currentImageIndex =
              (currentImageIndex - 1 + $images.length) % $images.length;
            showImage(currentImageIndex);
          });

          // Evento para el botón "next_img"
          $nextImgButton.on("click", function () {
            currentImageIndex = (currentImageIndex + 1) % $images.length;
            showImage(currentImageIndex);
          });

          // Mostrar la primera imagen al inicio
          showImage(currentImageIndex);
        });

        // Mostrar el primer paciente al cargar la página
        showPatient(currentPatientIndex);
      });

      // Función para actualizar el contenido del span con el data-name del paciente activo
      function updateActivePatientName() {
        var activeWrapper = $(".wrap_patients .patient_active");
        var activePatientName = activeWrapper.data("name");
        $(".bold").text(activePatientName);
      }
    }
  });

  let ADDRESS = "651 Garrison St #130, Lakewood, CO 80215";
  let ADDRESS_LINK = "https://maps.app.goo.gl/9cRdc271L9CYHgk36";
  let PHONE_NUMBER = "(720) 952-7261";
  let EMAIL = "management@skynbyhector.com";
  let FACEBOOK = "https://www.facebook.com/profile.php?id=61566856104988";
  let INSTAGRAM = "https://www.instagram.com/skynbyhector";

  $(".address").each(function () {
    $(this)
      .text(ADDRESS)
      .attr("href", ADDRESS_LINK) // Asigna el enlace a la dirección
      .attr("target", "_blank") // Hace que el enlace se abra en una nueva pestaña
      .click(function () {
        window.open(ADDRESS_LINK, "_blank"); // Se abre en nueva pestaña
      });
  });
  $(".phone_number").each(function () {
    $(this)
      .text(PHONE_NUMBER)
      .click(function () {
        window.location.href = "tel:" + PHONE_NUMBER;
      });
  });
  $(".email").each(function () {
    $(this)
      .text(EMAIL)
      .click(function () {
        window.location.href = "mailto:" + EMAIL;
      });
  });
  $(".facebook").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".instagram").each(function () {
    $(this).click(function () {
      window.open(INSTAGRAM, "_blank");
    });
  });
});
